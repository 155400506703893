.modal_pp{
    background-color: rgb(52, 55, 65);
    /*background-color: red;*/
    padding: 10px 0;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(16,22,26,.4), 0 0 0 rgba(16,22,26,0), 0 0 0 rgba(16,22,26,0);
}

.modal_pp .bp3-dialog-header{
    background: transparent;
}

.modal_pp .bp3-heading{
    color: rgba(255, 255, 255, 0.85);
}

.bp3-overlay-backdrop{
    background-color: rgba(16, 22, 26, 0.9);
}

.default-modal{
    background-color: rgb(52, 55, 65);
    width: 600px;
    padding: 10px 0;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.85);
    box-shadow: 0 0 0 1px rgba(16,22,26,.4), 0 0 0 rgba(16,22,26,0), 0 0 0 rgba(16,22,26,0);
    position: relative;
}

.close-button{
    position: absolute;
    right: 10px;
    font-size: 18px;
    cursor: pointer;
    padding: 5px 7px;
    border-radius: 4px;
}

.close-button:hover{
    background: rgba(167,182,194,.3);
}

.close-button::before{
    content: '✕';
    color: #5c7080;
    font-weight: bold;
}

.ms-Overlay{
    background-color: rgba(0, 0, 0, 0.8);
}