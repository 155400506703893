*:focus:focus-visible {
    outline-style: none !important;
}

html {
    /*background: #0e1b25 !important;*/
}

body {
    /*font-family: Helvetica, Arial, sans-serif;*/
    /*font-family: 'Monaco, Menlo, Consolas', sans-serif;*/
    letter-spacing: .01em;
    background: rgb(20, 21, 25);
    /*background: #0e1b25;*/
    /*color: rgba(255, 255, 255, 0.85);*/
    /*font-size: 14px;*/
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 3px;
}

::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 3px;
}

/*a, .link-like {*/
/*    color: #1890ff;*/
/*    cursor: pointer;*/
/*    text-decoration: none;*/
/*}*/

a:hover, .link-like:hover {
    text-decoration: underline;
}

.color-1 {
    color: rgba(255, 255, 255, 0.65);
}

.color-2 {
    color: rgba(255, 255, 255, 0.85);
}

/*label {*/
/*    color: rgba(255, 255, 255, 0.85) !important;*/
/*}*/

/*.label {*/
/*    color: rgba(255, 255, 255, 0.65) !important;*/
/*    font-size: 11px;*/
/*    margin-right: 3px;*/
/*}*/

.buttonLink {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    padding: 7px 14px;
    text-decoration: none;
}

.buttonLink:hover {
    color: #fff;
    background-color: #096dd9;
    border-color: #096dd9;
    text-decoration: none;
}

@keyframes online-animation {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    75% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes spinAround {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;

    border-top: 20px solid #f00;
}

.put-arrow-down {
    position: relative;
}

.header-link {
    color: rgba(255, 255, 255, 0.85);
}

.put-arrow-down:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid rgba(255, 255, 255, 0.65);
    position: absolute;
    right: 0;
    top: 6px;
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.header-menu {
    /*position: absolute;*/
    /*top: 13px;*/
    /*width: 150px;*/
    /*left: -50px;*/
}

.bp3-popover {
    box-shadow: 0 0 0 1px rgb(16 22 26 / 10%), 0 2px 4px rgb(16 22 26 / 20%), 0 8px 24px rgb(16 22 26 / 20%);
    transform: scale(1);
    border-radius: 3px;
    display: inline-block;
    z-index: 20
}

.bp3-popover-content {
    border-radius: 3px;
    position: relative;
    background: #fff;
    color: inherit;
    margin-top: 13px;
}

.bp3-popover-arrow {
    display: none;
}

.bp3-menu {
    background: #fff;
    border-radius: 3px;
    color: #182026;
    list-style: none;
    margin: 0;
    min-width: 180px;
    padding: 5px;
    text-align: left;
}

.bp3-menu-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-radius: 2px;
    color: inherit;
    line-height: 20px;
    padding: 5px 7px;
    text-decoration: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.bp3-menu-divider {
    border-top: 1px solid rgba(16, 22, 26, .15);
    display: block;
    margin: 5px;
}

.body-modal {
    overflow: hidden;
}

.mobile-menu-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
    text-align: center;
    font-size: 20px;
    margin-top: 10%;
    padding: 0 20px;


}

.donate-link {
    color: rgb(0, 128, 213);
}

.mobile-menu-wrapper button {
    font-size: 20px;
    height: 50px;
}

.mobile-menu-wrapper .logOut {
    margin-top: 20px;
}

.mobile-menu-wrapper .buy-me-coffee img {
    height: 45px;
}

h1, h2, h3, h4 {
    color: rgba(255, 255, 255, 0.65);
}

input:focus, input:focus-visible{
     outline: 0;
}
